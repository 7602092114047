/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

import { unsafeCSS, css } from 'lit-element';
import { _THEME } from '../../themes/styles';

export const LdPlatformStyles = css`
  :host {
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: stretch;
  
    font-family: ${unsafeCSS(_THEME.fonts.main_font)};
    --app-drawer-width: 64px;
    --app-drawer-scrim-background: ${unsafeCSS(_THEME.colors.app_drawer_scrim_background_color)};
    --app-drawer-content-container: {
      background-color: ${unsafeCSS(_THEME.colors.app_drawer_background_color)};
    };
  }
  
  app-header {
    height: 64px;
    background-color: ${unsafeCSS(_THEME.colors.app_header_background_color)};
    color: ${unsafeCSS(_THEME.colors.app_header_color)};
    box-shadow: inset 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
    box-shadow:  0px 5px 6px -3px rgba(0, 0, 0, 0.4);
  }
  
  ld-search-input {
    width: 450px;
    max-width: 100%;
    height: 50px;
    display: block;
    margin-left: 150px;
  }

  app-drawer {
    position: fixed;
    z-index:10;
  }

  div[main-title] {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    padding-left: 5%;
    padding-right: 0%;
  }
  
  @media only screen and (min-width: 600px ) {
    ld-search-input {
      width: 50px;
    }

    ld-search-input:focus {
      width: 450px;
    }
    
    div[main-title] {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 2.2px;
      text-transform: uppercase;
      padding-left: 5%;
       padding-right: 0%;

    }
  }
  
  @media only screen and (min-width: 1024px ) {
    ld-search-input {
      width: 250px;
    }

    ld-search-input:focus {
      width: 250px;
    }
    
    div[main-title] {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 2.2px;
      text-transform: uppercase;
      padding-right: 18%;
    }
  }

  @media only screen and (min-width: 1260px ) {
    ld-search-input {
      width: 450px;
    }

    ld-search-input:focus {
      width: 450px;
    }
    
    div[main-title] {
       text-align: center;
       font-weight: bold;
       font-size: 18px;
       line-height: 22px;
       letter-spacing: 2.2px;
       text-transform: uppercase;
       padding-right: 18%;
    }
  }
  
  
  
  section.sw-logo {
    height: 64px;
    width: 64px;
    background-color: rgba(255, 255, 255, 1);
  }
  
  #sw-logo {
    height: 28px;
    width: 80px;
    position: absolute;
    top: 18px;
    left: 70px;
  }
  
  #profile{
    display: flex;
    cursor: pointer;
  }

  #sw-logout{
    cursor: pointer;
  }
  
  .sw-header-bar-icon-alerts,
  .sw-header-bar-icon-profile {
    margin: 0 15px 0 0;
  }
  app-header paper-icon-button {
    --paper-icon-button-ink-color: ${unsafeCSS(_THEME.colors.primary_header_color)};
  }
  
  .dropdown-content {
    color: ${unsafeCSS(_THEME.colors.primary_header_color)};
  }
  paper-menu-button {
    color: ${unsafeCSS(_THEME.colors.primary_header_color)};
  }
  #user-dropdown {
    padding: 10px;
    width: 250px;
    min-height: 100px;
  }
  #user-dropdown paper-button {
    margin: 5px 0px;
  }
  span.account-name {
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 17px;
    pointer-events: none;
    margin-right: 10px;
  }
  .flex {
    -ms-flex: 1 1 0px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 0px;
    flex-basis: 0px;
  }
  
  footer {
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    font-size: 12px;
    -ms-flex: 1 1 0px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 0px;
    flex-basis: 0px;
    margin-right: 60px;
    max-height: 50px;
  }
  
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  
  footer ul {
    margin: 16px 25px;
    height: 20px;
    list-style: none;
  }
  
  footer ul>li {
    display: inline;
    margin-right: 15px;
  }
  
  footer ul>li>a {
    color: ${unsafeCSS(_THEME.colors.secondary_note_color)};
    text-decoration: none;
  }
  
  html, body {
    width: 100%;
    height: 100%;
  }
  
  main {
    margin-left: 64px;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    flex: auto;
  }
  
  app-header, main, footer {
    flex-shrink: 0;
  }
  
  ._pageModule {
    display: none;
  }
  ._pageModule[active] {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-left: 55px;

  }
  
  .main-content-inner-wrapper {
    width: calc(100%-100px);
    margin: 50px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    flex: auto;
  }
  
  /* The sticky class is added to the header with JS when it reaches its scroll position */
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 101;
  }
  
  .button-hide {
    display:none;
  }
  
  .button-show {
    display: block !important;
  }
  
  #privacyPolicyDialog {
    width:40%;
    min-height:95%;
  }
  
  paper-dialog#privacyPolicyDialog .privacy-language {
    margin:0 0 0 20px;
    padding: 15px 5px 0 5px;
  }
  
  paper-dialog#privacyPolicyDialog .privacy-language a {
    padding: 0 5px;
  }
  
  paper-dialog#privacyPolicyDialog .privacy-language a:first-child {
    padding-left:0;
  }
  
  paper-button#privacyPolicyDialogButtonClose {
    display:none;
  }

  paper-toast {
    border-top-width: 3px;
    border-top-style: solid;
    border-top-color: ${unsafeCSS(_THEME.colors.primary_color)};
    z-index: 999999;
  }

  paper-toast[data-notification-type='success'] {
    border-top-color: var(--sw-secondary-green-300);
  }

  paper-toast[data-notification-type='release'] {
    border-top-color: ${unsafeCSS(_THEME.colors.selected_menu_item)};
  }
`;

export default LdPlatformStyles;
