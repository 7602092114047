/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

'use strict';

import { css, unsafeCSS } from 'lit-element';
import { _THEME } from '../../../../themes/styles';
import { displayFlex, horizontal, startJustified } from 'lit-flexbox-literals';

export const LdPlatformModulesAssetsStyles = css`
  :host {
    display: flex;
    flex-direction: row;
    flex: auto;
  }

  ::part(ld-part-checkbox) {
    margin-left: 10px;
  }

  ld-performance-display-counter {
    height: 100%;
    display: block;
    margin-top: -45px;
    margin-left: -163px;
    z-index: 1000;
    position: fixed;
    width: 100%;
    background-color: white;
  }

  paper-fab.fab-item-left {
    margin: 0 28px 0 -28px;
    --paper-fab-background: ${unsafeCSS(_THEME.colors.fab_background)};
    margin-left: -80px;

  }

  div.fab-container {
    position: absolute;
    top: 164px;
    left: 86px;
    --paper-fab-background: ${unsafeCSS(_THEME.colors.fab_background)};
    z-index: 100;
  }

  div.fab-container > paper-fab {
    margin-bottom: 8px;
  }

  div.fab-container > paper-fab.discard {
    --paper-fab-background: ${unsafeCSS(_THEME.colors.fab_secondary_background)};
  }

  dialog {
    --fade-in-duration: 0.2s;

    border: none;
    padding: 25px;
    box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0px 12.5px 10px rgba(0, 0, 0, 0.035),
    0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0px 100px 80px rgba(0, 0, 0, 0.07);

    -webkit-animation: fadein var(--fade-in-duration); /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein var(--fade-in-duration); /* Firefox < 16 */
    -ms-animation: fadein var(--fade-in-duration); /* Internet Explorer */
    -o-animation: fadein var(--fade-in-duration); /* Opera < 12.1 */
    animation: fadein var(--fade-in-duration);
  }

  dialog::backdrop {
    background: rgba(0, 0, 0, 0.7);
  }

  dialog .loader {
    display: none;
  }

  dialog.busy .loader {
    display: initial;
  }
  
  .biggerDialog {
    width:40%; height:90%;
  }

  .modal-title {
    color: ${unsafeCSS(_THEME.colors.secondary_text_color)};
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .form-actions {
    display: flex;
    flex-direction: row;
    margin-block-start: 25px;
    min-height: 60px;
  }

  .button-alignment {
    justify-content: flex-end;
    display: flex;
  }

  paper-button.primary-action {
    text-transform: uppercase;
  }

  paper-button.secondary-action {
    text-transform: uppercase;
  }

  paper-button + paper-button {
    margin-inline-start: 6px;
  }

  .primary-and-secondary-form-actions {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
  }

  .detail-container {
    width: calc(100% - 50px);
    height: auto;
    padding: 0;
    margin: 20px 0 20px 50px;
    background-color: var(--platform-assets-detail-view-background-color, var(--sw-primary-background-color, #f1f1f1));
    color: #000;
  @apply --layout-vertical;
  @apply --layout-wrap;
  }

  .detail-header-container {
    height: 60px;
  @apply --layout-horizontal;
  @apply --layout-wrap;
  }

  .detail-header {
    width: 90%;
    max-width: 800px;
  }

  .detail-header h2 {
    margin-left: 25px;
    width: 100%;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  .detail-header h2::before {
    content: "/ ";
    color: var(--platform-frame-assets-highlight-color, var(--sw-primary-highlight-color, #eb0028));
  }

  .detail-header-collapse {
    margin: auto;
    text-align: center;
    width: 10%
  }

  .align-center {
    text-align: center;
  }

  paper-button {
    margin: 12px 0px;
    background-color: ${unsafeCSS(_THEME.colors.button_background_color)};
    color: ${unsafeCSS(_THEME.colors.primary_color)};
    font-weight: bold;
    padding-inline: 20px;
  }

  paper-input, paper-dropdown-menu {
    margin-bottom: 10px;
  }

  .grid-container {
    width: calc(100% - 0px);
    display: flex;
    flex-direction: column;
    flex: auto;
  }

  @supports (-ms-ime-align:auto) {

    .grid-container {
      width: calc(100% - 0px);
      height: 80%;
      margin-left: 50px;
    }
  }

  .container {
    width: calc(100% - 0px);
  }

  .headline {
    background-color: #fff;
    height: auto;
    padding: 2px 5px;
  }

  .headline h3 {
    text-transform: uppercase;
    font-size: 18px;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #000;
  }

  .headline h3::before {
    content: "/ ";
    font-weight: 800;
    color: var(--platform-frame-assets-highlight-color, var(--sw-primary-highlight-color, #eb0028));
  }

  

  ::slotted(h3) {
    background-color: #fff;
    height: auto;
    padding: 2px 5px;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #000;
  }

  ::slotted(h3)::before {
    content: "/ ";
    font-weight: 800;
    color: var(--platform-frame-assets-highlight-color, var(--sw-primary-highlight-color, #eb0028));
  }
  @media only screen and (min-width: 600px ) {
    .inner-container {
      margin: 0 auto;
      width: 28em;
    }

    .outer-container {
      background-color: #fff;
      width: calc(100% - 50px);
      margin-left: 20px;
      padding: 25px;
    }

    .detail-container {
      width: calc(100% - 50px);
      height: auto;
      padding: 0;
      margin: 20px 0 20px 20px;
      background-color: var(--platform-assets-detail-view-background-color, var(--sw-primary-background-color, #f1f1f1));
      color: #000;
    @apply --layout-vertical;
    @apply --layout-wrap;
    }
  }
  @media only screen and (min-width: 1260px ) {
    .inner-container {
      margin: 0 auto;
      width: 50em;
    }

    .outer-container {
      background-color: #fff;
      width: calc(100% - 50px);
      margin-left: 50px;
      padding: 25px;
    }

    .detail-container {
      width: calc(100% - 50px);
      height: auto;
      padding: 0;
      margin: 20px 0 20px 50px;
      background-color: var(--platform-assets-detail-view-background-color, var(--sw-primary-background-color, #f1f1f1));
      color: #000;
    @apply --layout-vertical;
    @apply --layout-wrap;
    }
  }

  paper-toggle-button.red {
    --paper-toggle-button-checked-bar-color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
    --paper-toggle-button-checked-button-color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
    --paper-toggle-button-checked-ink-color: ${unsafeCSS(_THEME.colors.primary_highlight_color)};
  }

`;

export default LdPlatformModulesAssetsStyles;
